<script setup lang="ts">
import type { Game } from "@/types";

const { t } = useT();

defineProps<{
	game: Game;
}>();
</script>

<template>
	<div v-if="game.isNew" class="badges-row">
		<ABadge background="var(--cebal)" variant="info" autosize>
			<AText :size="12" :modifiers="['uppercase', 'semibold']"> {{ t("game new") }}</AText>
		</ABadge>
	</div>
</template>

<style scoped lang="scss">
.badges-row {
	display: flex;
	align-items: center;
	gap: 4px;
}
</style>
